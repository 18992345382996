import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/node_modules/next/dist/client/components/render-from-template-context.js");
